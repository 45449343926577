import { useEffect, useState, useRef } from "react";
import { Redirect } from "react-router";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Alert, Card, Spinner } from "reactstrap";
import { MdLock } from "react-icons/md";
import {
  formattedDate,
  formattedTime,
  getMonthsAgo,
} from "../../components/formatted/FormattedDateAndTime";
import CampaignFilter from "./CampaignFilter";
import { startWithLowercase } from "../../components/utils/Date";
import CampaignHeader from "./CampaignHeader";
import { Dropdown } from "../../components/dropdownMenu/Dropdown";
import { getCommentsData } from "../../components/utils/Comments";
import CampaignCreateModal from "./CampaignCreateModal";
import { getCampaigns } from "../../services/notification";
import Loading from "../../components/utils/Loading";
import CampaignUpdateModal from "./CampaignUpdateModal";
import CampaignDeleteModal from "./CampaignDeleteModal";
import ForbiddenModule from "../../components/ForbiddenModule";

const Campaign = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const token = Cookies.get("userToken");
  const corporationModules = JSON.parse(
    localStorage.getItem("corporationModules"),
  );
  const [campaign, setCampaign] = useState(true);
  const [periodStart, setPeriodStart] = useState(
    getMonthsAgo(new Date(), 3).toISOString(),
  );
  const [periodStop, setPeriodStop] = useState(null);
  const filterRef = useRef(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(0);
  const [modalCampaign, setModalCampaign] = useState(false);
  const [campaignPeriods, setCampaignPeriods] = useState([]);
  const [periodName, setPeriodName] = useState(null);
  const [periodFilterValue, setPeriodFilterValue] = useState("0");
  const [comments, setComments] = useState([]);
  const [addInfo, setAddInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [campaignUpdate, setCampaignUpdate] = useState(false);
  const [campaignDelete, setCampaignDelete] = useState(false);
  const [formData, setFormData] = useState({});

  const viewMode = Cookies.get("viewMode") || "client";
  const isAkanthasAccount = (Cookies.get("userEmail") || "").includes(
    "@akanthas.com",
  );

  const fetchCampaignsPeriods = async () => {
    await getCampaigns({
      start: periodStart,
      stop: periodStop,
    }).then((response) => {
      setCampaignPeriods(response.data.campaigns);
    });
  };

  useEffect(
    () => {
      fetchCampaignsPeriods();
    },
    // eslint-disable-next-line
    [periodStart, periodStop],
  );

  useEffect(() => {
    if (corporationModules !== null) {
      setCampaign(corporationModules?.showCampaignModule || false);
    }
  }, [corporationModules]);

  useEffect(
    () => {
      const fetchComments = async () => {
        setIsLoading(true);
        let periodString = "";
        if (i18n.language === "fr") {
          periodString = `du ${formattedDate(
            campaignPeriods[selectedCampaign].start,
            i18n.language,
            true,
          )} au ${formattedDate(
            campaignPeriods[selectedCampaign].stop,
            i18n.language,
            true,
          )}`;
        } else {
          periodString = `${formattedDate(
            campaignPeriods[selectedCampaign].start,
            i18n.language,
            true,
          )} ${t("to")} ${formattedDate(
            campaignPeriods[selectedCampaign].stop,
            i18n.language,
            true,
          )}`;
        }
        const response = await getCommentsData(
          campaignPeriods[selectedCampaign].start,
          campaignPeriods[selectedCampaign].stop,
          [],
        );
        setPeriodName(periodString);
        setComments(response);
        setIsLoading(false);
      };
      if (campaignPeriods.length > 0) {
        fetchComments();
      }
    },
    // eslint-disable-next-line
    [campaignPeriods, selectedCampaign, i18n.language],
  );

  const handleCsvExport = async () => {
    const label = [
      t("Notification"),
      t("Flux"),
      t("Positioning"),
      t("Treatment"),
      t("Error type"),
      t("Numbers"),
      t("Removed"),
      t("Reason for non-removal"),
    ];

    const lastData = comments.map((comment) => [
      `${formattedDate(
        comment.notificationDate,
        i18n.language,
        true,
      )} ${formattedTime(comment.notificationDate)}`,
      comment.wasteName,
      comment?.positioning,
      `${formattedDate(
        comment.time,
        i18n.language,
        true,
      )} ${formattedTime(comment.time)}`,
      comment.material,
      comment.number,
      comment.objectRemoved ? t("Yes") : t("No"),
      comment?.reason?.label || "-",
    ]);
    const csvContent = [label, ...lastData];
    const content = csvContent.map((row) => row.join(";")).join("\n");
    const blob = new Blob([content], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${t("Sorting campaign")} ${startWithLowercase(periodName)}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpenFilter(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (token) {
    return (
      <div className="base">
        <h1 className="flex flex-row">
          {!campaign && (
            <MdLock style={{ marginRight: "5px", height: 25, marginTop: 10 }} />
          )}
          {`${t("My")} ${t("Sorting campaign").toLowerCase()}`}
        </h1>
        {!campaign &&
        !(isAkanthasAccount && viewMode === "admin") &&
        location.pathname.includes("campaign") ? (
          <ForbiddenModule viewType={"campaign"} />
        ) : (
          <>
            <div
              className={`relative flex flex-col w-full ${!openFilter && "h-12"}`}
            >
              <div className="flex flex-row w-full gap-2 bg-white rounded-md px-2">
                <div className="flex flex-row items-center data-bold">
                  <button
                    className="operation-data operation-button my-auto"
                    onClick={() => setModalCampaign(true)}
                    onMouseOver={() => setAddInfo(true)}
                    onMouseOut={() => setAddInfo(false)}
                  >
                    <i className="fas fa-plus-circle icon-header"></i>
                  </button>
                  {addInfo && (
                    <span className="absolute bg-yellow text-white px-2 rounded -top-5">
                      {t(`${"Create a sorting campaign".toLowerCase()}`)}
                    </span>
                  )}
                </div>
                <div className="flex flex-row w-3/5 items-center gap-5 h-12">
                  <div className="w-1/6 text-center">
                    <i
                      className={`fas fa-angle-double-left text-2xl cursor-pointer ${selectedCampaign === 0 ? "text-[#8a8989]" : "text-[#00A7A6]"}`}
                      onClick={(event) => {
                        event.preventDefault();
                        setSelectedCampaign(Math.max(0, selectedCampaign - 1));
                      }}
                    ></i>
                  </div>
                  <div className="w-4/6 text-center">
                    <h6 className="data-bold mt-1">
                      {isLoading ? (
                        <Spinner className="mt-1" color="secondary">
                          Loading...
                        </Spinner>
                      ) : periodName ? (
                        `${t("Sorting campaign").toLowerCase()} ${startWithLowercase(periodName)}`
                      ) : (
                        t("No sorting campaigns")
                      )}
                    </h6>
                  </div>
                  <div className="w-1/6 text-center">
                    <i
                      className={`fas fa-angle-double-right text-2xl cursor-pointer ${selectedCampaign === campaignPeriods.length - 1 ? "text-[#8a8989]" : "text-[#00A7A6]"}`}
                      onClick={(event) => {
                        event.preventDefault();
                        setSelectedCampaign(
                          Math.min(
                            selectedCampaign + 1,
                            campaignPeriods.length - 1,
                          ),
                        );
                      }}
                    ></i>
                  </div>
                </div>
                <div className="flex flex-row items-center flex-end gap-5 w-2/5 h-12">
                  <span>{t("Advanced search")}</span>
                  <i
                    class="fas fa-sliders-h text-2xl text-[#686868] mr-1 cursor-pointer"
                    onClick={() => setOpenFilter(!openFilter)}
                  ></i>
                </div>
              </div>

              {openFilter ? (
                <div className="w-full flex flex-end">
                  <CampaignFilter
                    filterRef={filterRef}
                    periodStart={periodStart}
                    setPeriodStart={setPeriodStart}
                    setPeriodStop={setPeriodStop}
                    campaignPeriods={campaignPeriods}
                    selectedCampaign={selectedCampaign}
                    setSelectedCampaign={setSelectedCampaign}
                    periodFilterValue={periodFilterValue}
                    setPeriodFilterValue={setPeriodFilterValue}
                    setCampaignUpdate={setCampaignUpdate}
                    setFormData={setFormData}
                    setCampaignDelete={setCampaignDelete}
                  />
                </div>
              ) : null}
            </div>

            <div className="flex flex-row flex-end w-full mt-3">
              <Dropdown
                text={t("Export")}
                disabled={isLoading || comments.length === 0}
                className="ml-5"
                children={
                  <>
                    <span
                      onClick={() => handleCsvExport()}
                      className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 cursor-pointer"
                    >
                      CSV (.csv)
                    </span>
                  </>
                }
              />
            </div>

            <Card className="table">
              <div>
                <CampaignHeader
                  classColumnTable={`hidden lg:flex xl:flex 2xl:flex w-full justify-between bg-white rounded h-14 mx-0 my-2`}
                />
                {isLoading ? (
                  <Loading color="#F5F5F5" />
                ) : (
                  <article className="content-table">
                    {comments && comments.length > 0 ? (
                      comments.map((comment, index) => (
                        <article className="flex flex-row w-full bg-white mt-2 pt-2.5 pb-2.5 px-2 rounded-md">
                          <span className="w-2/12 text-sm">
                            {comment?.notificationDate
                              ? `${formattedDate(
                                  comment.notificationDate,
                                  i18n.language,
                                  true,
                                )}, ${formattedTime(comment.notificationDate)}`
                              : "-"}
                          </span>
                          <span className="w-1/12 text-sm">
                            {comment.wasteName}
                          </span>
                          <span className="w-1/12 text-sm">
                            {comment.positioning}
                          </span>
                          <span className="w-2/12 text-sm">
                            {formattedDate(comment.time, i18n.language, true)},{" "}
                            {formattedTime(comment.time)}
                          </span>
                          <span className="w-2/12 text-sm">
                            {comment.material}
                          </span>
                          <span className="w-1/12 text-sm">
                            {comment.number}
                          </span>
                          <span className="w-1/12 text-sm">
                            {comment.objectRemoved ? t("Yes") : t("No")}
                          </span>
                          <span className="w-2/12 text-sm">
                            {comment?.reason?.value === "other"
                              ? comment?.specificReason
                              : comment?.reason?.label || "-"}
                          </span>
                        </article>
                      ))
                    ) : (
                      <Alert color="danger" className="mt-3 text-center">
                        {t("No data to display")}!
                      </Alert>
                    )}
                  </article>
                )}
              </div>
            </Card>
            {modalCampaign && (
              <CampaignCreateModal
                modalCampaign={modalCampaign}
                setModalCampaign={setModalCampaign}
                fetchCampaignsPeriods={fetchCampaignsPeriods}
              />
            )}
            {campaignUpdate && (
              <CampaignUpdateModal
                campaign={formData}
                campaignUpdate={campaignUpdate}
                setCampaignUpdate={setCampaignUpdate}
                fetchCampaignsPeriods={fetchCampaignsPeriods}
              />
            )}
            {campaignDelete && (
              <CampaignDeleteModal
                campaign={formData}
                campaignDelete={campaignDelete}
                setCampaignDelete={setCampaignDelete}
                fetchCampaignsPeriods={fetchCampaignsPeriods}
              />
            )}
          </>
        )}
      </div>
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
};

export default Campaign;
