import { Redirect, useLocation } from "react-router";
import { useEffect, useState, useRef } from "react";
import ActivityHistoryTimeLine from "./ActivityHistoryTimeLine";
import {
  formattedContainerId,
  formattedTypeContainer,
} from "../../components/formatted/FormattedText";
import ActivityCollection from "./ActivityCollection";
import Cookies from "js-cookie";
import {
  getKeywordFilterOptions,
  getSuperContainerList,
} from "../../services/container";
import { useTranslation } from "react-i18next";
import {
  englishMonth,
  formattedDate,
  frenchMonth,
  getEndOfMonth,
  getStartOfMonth,
  getStartOfWeek,
  spanishMonth,
} from "../../components/formatted/FormattedDateAndTime";
import ActivityFilter from "./ActivityFilter";
import {
  capitalizeFirstLetter,
  startWithLowercase,
} from "../../components/utils/Date";

const Activity = () => {
  const { t, i18n } = useTranslation();
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");

  const location = useLocation();
  const stateContainer = location.state || undefined;
  const displayCollecte =
    stateContainer !== undefined ? stateContainer.displayCollecte : false;
  const keywordF =
    stateContainer !== undefined ? stateContainer.keywordFilter : [];

  const [keywordFilter, setKeywordFilter] = useState(keywordF || []);
  const [keywordFilterOptions, setKeywordFilterOptions] = useState([]);

  const [periodHistoryStop, setPeriodHistoryStop] = useState(new Date());
  const [periodHistoryStart, setPeriodHistoryStart] = useState(
    getStartOfWeek(new Date()).toISOString(),
  );

  const [showHistory, setShowHistory] = useState(
    displayCollecte ? true : false,
  );
  const [showContainerCollection, setShowContainerCollection] = useState(
    displayCollecte ? false : true,
  );
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const multiselectKeywordRef = useRef(null);
  const filterRef = useRef(null);
  const [superContainers, setSuperContainers] = useState({});
  const [showDate, setShowDate] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [handleValidate, setHandleValidate] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState("0");
  const [periodValue, setPeriodValue] = useState(null);
  const [keyword, setKeyword] = useState([]);

  let periodString = null;
  if (i18n.language === "fr") {
    periodString = `Depuis Lundi`;
  } else if (i18n.language === "en") {
    periodString = `Since Monday`;
  } else {
    periodString = "Desde el lunes";
  }

  const [formatedPeriod, setFormatedPeriod] = useState(periodString);
  const [periodName, setPeriodName] = useState(periodString);

  useEffect(
    () => {
      getKeywordFilterOptions({
        keywordFilter: JSON.stringify(keywordFilter.value),
      }).then((res) => {
        if (res.status === 200) {
          setKeywordFilterOptions(res.data.keywordFilterOptions);
        }
      });
    },
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    getSuperContainerList()
      .then((response) => {
        if (response.data.superContainers.length > 0) {
          setSuperContainers(response.data.superContainers[0]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [API, token]);

  const labeledkeywordFilterOptions = keywordFilterOptions
    .filter((keyword) => keyword.key !== null)
    .map((keyword) => {
      if (keyword.type === "type") {
        keyword = { ...keyword, key: formattedTypeContainer(keyword.key) };
      } else if (keyword.type === "id") {
        keyword = {
          ...keyword,
          key: formattedContainerId(keyword.key) + keyword.key,
        };
      }
      return keyword;
    });

  const selectPeriodFilter = (value) => {
    const now = new Date();
    let startOfLastMonth;
    let endOfLastMonth;
    setSelectedPeriod(value);
    setKeywordFilter(keyword);
    switch (value) {
      case "0": {
        const startOfWeek = getStartOfWeek(now);
        setPeriodHistoryStart(startOfWeek.toISOString());
        setPeriodHistoryStop(now.toISOString());
        break;
      }
      case "1": {
        startOfLastMonth = getStartOfMonth(
          new Date(now.getFullYear(), now.getMonth()),
        );
        endOfLastMonth = getEndOfMonth(
          new Date(now.getFullYear(), now.getMonth()),
        );
        break;
      }
      case "2": {
        startOfLastMonth = getStartOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 1),
        );
        endOfLastMonth = getEndOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 1),
        );
        break;
      }
      case "3":
        startOfLastMonth = getStartOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 2),
        );
        endOfLastMonth = getEndOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 2),
        );
        break;

      case "4":
        startOfLastMonth = getStartOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 3),
        );
        endOfLastMonth = getEndOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 3),
        );
        break;

      case "5":
        startOfLastMonth = getStartOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 4),
        );
        endOfLastMonth = getEndOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 4),
        );
        break;

      case "6":
        startOfLastMonth = getStartOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 5),
        );
        endOfLastMonth = getEndOfMonth(
          new Date(now.getFullYear(), now.getMonth() - 5),
        );
        break;

      default:
        break;
    }

    if (value === "0") {
      if (i18n.language === "fr") {
        periodString = `Depuis Lundi`;
      } else if (i18n.language === "en") {
        periodString = `Since Monday`;
      } else {
        periodString = "Desde el lunes";
      }
    } else if (value === "1") {
      if (i18n.language === "fr") {
        periodString = `Depuis le 1er ${capitalizeFirstLetter(frenchMonth[now.getMonth()])}`;
      } else if (i18n.language === "en") {
        periodString = `Since 1st ${capitalizeFirstLetter(englishMonth[now.getMonth()])}`;
      } else {
        periodString = `Desde el 1º ${capitalizeFirstLetter(spanishMonth[now.getMonth()])}`;
      }
    } else {
      if (value !== "calendar") {
        if (i18n.language === "fr") {
          periodString = `Du 1er ${capitalizeFirstLetter(frenchMonth[startOfLastMonth.getMonth()])} au ${endOfLastMonth.getDate()} ${capitalizeFirstLetter(frenchMonth[endOfLastMonth.getMonth()])}`;
        } else if (i18n.language === "en") {
          periodString = `1st ${capitalizeFirstLetter(englishMonth[startOfLastMonth.getMonth()])} to ${endOfLastMonth.getDate()} ${capitalizeFirstLetter(englishMonth[endOfLastMonth.getMonth()])}`;
        } else {
          periodString = `1º ${capitalizeFirstLetter(spanishMonth[startOfLastMonth.getMonth()])} a ${endOfLastMonth.getDate()} ${capitalizeFirstLetter(spanishMonth[endOfLastMonth.getMonth()])}`;
        }
      }
    }
    if (value !== "calendar" && value !== "0") {
      setPeriodHistoryStart(startOfLastMonth.toISOString());
      setPeriodHistoryStop(endOfLastMonth.toISOString());
      setPeriodName(periodString);
    } else {
      setPeriodName(formatedPeriod);
    }
    setShowDate(false);
    setHandleValidate(handleValidate + 1);
  };

  const onChangeCalendar = (event) => {
    setPeriodHistoryStop(new Date(event[1]).toISOString());
    setPeriodHistoryStart(new Date(event[0]).toISOString());
    periodString = `${i18n.language === "fr" ? "Du" : ""}${formattedDate(new Date(event[0]), i18n.language)} ${t("to")} ${formattedDate(new Date(event[1]), i18n.language)}`;
    setFormatedPeriod(periodString);
    setDisplayCalendar(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpenFilter(false);
        setShowDate(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (token) {
    return (
      <div className="base">
        <h1>{t("My Historical Data")}</h1>

        <nav className="flex mx-1 justify-between mb-4 mt-4">
          <div className="w-1/4">
            <span
              onClick={() => {
                setShowHistory(false);
                setShowContainerCollection(true);
              }}
              className={`text-cyan text-base cursor-pointer px-5 py-2 ${showContainerCollection ? "bg-inherit border-b-4 border-cyan" : "bg-gainsboro rounded-md"}`}
            >
              {t("Rotation")}
            </span>
            <span
              onClick={() => {
                setShowHistory(true);
                setShowContainerCollection(false);
              }}
              className={`text-cyan text-base cursor-pointer px-5 py-2 ml-2 ${showHistory ? "bg-inherit border-b-4 border-cyan" : "bg-gainsboro rounded-md"} `}
            >
              {t("Activity")}
            </span>
          </div>
        </nav>
        <div
          className={`relative flex flex-col w-full ${!openFilter && "h-12"}`}
          ref={filterRef}
        >
          <div className="flex flex-row w-full bg-white h-12 rounded-md px-2 mt-2">
            <div className="flex flex-row items-center w-3/5 gap-2 data-bold">
              <i className="fas fa-history"></i>
              <span>
                {t("Activities")} {startWithLowercase(periodName)}
              </span>
            </div>
            <div className="flex flex-row items-center flex-end gap-5 w-2/5 h-12">
              <span>{t("Advanced search")}</span>
              <i
                className="fas fa-sliders-h text-2xl text-[#686868] mr-1 cursor-pointer"
                onClick={() => setOpenFilter(!openFilter)}
              ></i>
            </div>
          </div>

          {openFilter ? (
            <ActivityFilter
              labeledkeywordFilterOptions={labeledkeywordFilterOptions}
              multiselectKeywordRef={multiselectKeywordRef}
              keywordFilter={keywordFilter}
              setKeywordFilter={setKeywordFilter}
              onChangeCalendar={onChangeCalendar}
              displayCalendar={displayCalendar}
              setDisplayCalendar={setDisplayCalendar}
              periodHistoryStart={periodHistoryStart}
              periodHistoryStop={periodHistoryStop}
              selectPeriodFilter={selectPeriodFilter}
              selectedPeriod={selectedPeriod}
              showDate={showDate}
              setShowDate={setShowDate}
              periodValue={periodValue}
              setPeriodValue={setPeriodValue}
              keyword={keyword}
              setKeyword={setKeyword}
            />
          ) : null}
        </div>

        {showHistory && (
          <ActivityHistoryTimeLine
            keywordFilter={keywordFilter}
            periodHistoryStart={periodHistoryStart}
            periodHistoryStop={periodHistoryStop}
            setKeywordFilterOptions={setKeywordFilterOptions}
            onChangeCalendar={onChangeCalendar}
            displayCalendar={displayCalendar}
            setDisplayCalendar={setDisplayCalendar}
            superContainers={superContainers}
            handleValidate={handleValidate}
            setOpenFilter={setOpenFilter}
            periodName={periodName}
          />
        )}

        {showContainerCollection && (
          <ActivityCollection
            keywordFilter={keywordFilter}
            periodHistoryStart={periodHistoryStart}
            periodHistoryStop={periodHistoryStop}
            onChangeCalendar={onChangeCalendar}
            displayCalendar={displayCalendar}
            setDisplayCalendar={setDisplayCalendar}
            superContainers={superContainers}
            handleValidate={handleValidate}
            setOpenFilter={setOpenFilter}
          />
        )}
      </div>
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
};

export default Activity;
