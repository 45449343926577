import { FaSignal } from "react-icons/fa";

export const truncateText = (text, maxLength) => {
  if (text && text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};

export const formattedStatus = (status) => {
  if (status === "drop_driver") {
    return "Dépôt";
  } else if (status === "remove_driver_exchange") {
    return "Enlevement/Echange";
  } else if (status === "collect") {
    return "Collecte confirmé";
  } else if (status === "maintenance") {
    return "Maintenance";
  } else if (status === "reception_logistic") {
    return "Réception";
  } else if (status === "store_logistic") {
    return "Stockage";
  } else if (status === "in_use_logistic") {
    return "Utilisation";
  } else if (status === "treatment") {
    return "Traitement";
  } else {
    return "-";
  }
};

export const formattedColorMaterial = (materialToFormat) => {
  const materialToFormatLower = materialToFormat.toLowerCase().replace("_", "");
  const materialList = JSON.parse(
    sessionStorage.getItem("materialsDescription"),
  );

  for (const mat of materialList) {
    if (
      mat.referenceName.toLowerCase().replace("_", "") === materialToFormatLower
    ) {
      return mat?.displayColor;
    }

    for (const possibleName of mat.possibleNames) {
      if (
        possibleName.toLowerCase().replace("_", "") === materialToFormatLower
      ) {
        return mat?.displayColor;
      }
    }
  }

  console.warn(`Color not found for ${materialToFormat}`);
  // return default color
  return "#00a7a6";
};

export const formattedMaterial = (materialToFormat, language) => {
  const materialToFormatLower = materialToFormat.toLowerCase().replace("_", "");
  const materialList = JSON.parse(
    sessionStorage.getItem("materialsDescription"),
  );

  for (const mat of materialList) {
    if (
      mat.referenceName.toLowerCase().replace("_", "") === materialToFormatLower
    ) {
      if (language === "fr") {
        return mat.frenchFormattedName;
      } else {
        return mat.englishFormattedName;
      }
    }

    for (const possibleName of mat.possibleNames) {
      if (
        possibleName.toLowerCase().replace("_", "") === materialToFormatLower
      ) {
        if (language === "fr") {
          return mat.frenchFormattedName;
        } else {
          return mat.englishFormattedName;
        }
      }
    }
  }

  console.warn(`Material name not found for ${materialToFormat}`);
  // return orginal name when material not found
  return materialToFormat;
};

export const formattedRole = (role, admin) => {
  if (role === "client") {
    return (
      <div>
        <i className="fas fa-users ico-action ico-user"></i>
        <span>Client</span>
      </div>
    );
  } else if (role === "owner" && admin === true) {
    return (
      <div>
        <i className="fas fa-user-lock ico-action ico-user"></i>
        <span>Admin.</span>
      </div>
    );
  } else if (role === "owner" && admin === false) {
    return (
      <div>
        <i className="fas fa-user-shield ico-action ico-user"></i>
        <span>Responsable</span>
      </div>
    );
  } else if (role === "driver") {
    return (
      <div>
        <i className="fas fa-user-clock ico-action ico-user"></i>
        <span>Chauffeur</span>
      </div>
    );
  } else if (role === "logistic") {
    return (
      <div>
        <i className="fas fa-user-tie ico-action ico-user"></i>
        <span>Logistique</span>
      </div>
    );
  } else {
    return "no status yet";
  }
};

export const formattedTypeContainer = (type) => {
  if (type === "bigbag") {
    return "Big Bag";
  } else if (type === "ampliroll_bin") {
    return "Benne Ampliroll";
  } else if (type === "chain_bin") {
    return "Benne à chaine";
  } else if (type === "compactor") {
    return "Compacteur";
  } else if (type === "geobox") {
    return "Geobox";
  } else if (type === "intermediate_bulk_container") {
    return "Grand récipient vrac";
  } else if (type === "lattice_box") {
    return "Box grillagé";
  } else if (type === "metallic_drum") {
    return "Fut métalique";
  } else if (type === "plastic_drum") {
    return "Fut plastique";
  } else if (type === "lego") {
    return "Alvéole";
  } else if (type === "unknown") {
    return "Inconnu";
  } else {
    return "-";
  }
};

export const formattedRoleAddress = (role) => {
  if (role === "client") {
    return "Client";
  } else if (role === "sorting_plant") {
    return "C. de tri";
  } else if (role === "maintenance_centre") {
    return "C. de maintenance";
  } else if (role === "building_site") {
    return "Chantier";
  } else if (role === "dump") {
    return "Déchetterie";
  } else if (role === "industrial_site" || role === "industrial") {
    return "Site industriel";
  } else if (role === "landfill_site") {
    return "Centre d'enfouissement";
  } else if (role === "incineration_centre") {
    return "Centre d'incinération";
  } else {
    return "not status yet";
  }
};

export const formattedRoleUser = (role) => {
  if (role === "client") {
    return "Client";
  } else if (role === "owner") {
    return "Responsable";
  } else if (role === "driver") {
    return "Chauffeur";
  } else if (role === "logistic") {
    return "Responsable logistique";
  }
};

export const formattedError = (error) => {
  if (error === "email_already_exists") {
    return "Cet email est déjà utilisé";
  } else if (error === "material_name_waste_already_exists") {
    return "Ce nom du déchet est déjà utilisé";
  } else if (error === "missing_required_fields") {
    return "Merci de remplir tous les champs";
  } else if (error === "access forbidden") {
    return "Accès refusé";
  } else if (error === "invalid password") {
    return "Mot de passe incorrect";
  } else if (error === "invalid email") {
    return "Email incorrect";
  } else if (error === "content_category_missing") {
    return "Merci de sélectionner une matière";
  } else if (error === "invalid_link") {
    return "Lien non valide";
  } else if (error === "user_not_found" || error === "User not found") {
    return "Utilisateur inexistant";
  } else if (error === "expired_link") {
    return "Lien expiré";
  } else if (error === "incomplete_address") {
    return "Adresse incomplète : définissez les heures d'ouvertures.";
  } else {
    return "Erreur non défini : " + error;
  }
};

export const formattedLevelColor = (
  filling,
  limitFillingLevel,
  type = "normal",
) => {
  const dangerLimitFilling = limitFillingLevel + (limitFillingLevel * 5) / 100;
  const oneQuarterLimitFilling = limitFillingLevel / 4;

  switch (type) {
    case "reverse":
      if (filling >= 0 && filling <= oneQuarterLimitFilling) {
        return "border-solid border-status-red-danger bg-status-red-danger text-status-red-danger";
      } else if (
        filling > oneQuarterLimitFilling &&
        filling < limitFillingLevel
      ) {
        return "border-solid border-status-red bg-status-red text-status-red";
      } else if (filling >= limitFillingLevel && filling < dangerLimitFilling) {
        return "border-solid border-status-orange bg-status-orange text-status-orange";
      } else if (filling >= dangerLimitFilling) {
        return "border-solid border-status-green bg-status-green text-status-green";
      }
      break;

    default:
      if (filling >= 0 && filling <= oneQuarterLimitFilling) {
        return "border-solid border-status-green bg-status-green text-status-green";
      } else if (
        filling > oneQuarterLimitFilling &&
        filling < limitFillingLevel
      ) {
        return "border-solid border-status-orange bg-status-orange text-status-orange";
      } else if (filling >= limitFillingLevel && filling < dangerLimitFilling) {
        return "border-solid border-status-red bg-status-red text-status-red";
      } else if (filling >= dangerLimitFilling) {
        return "border-solid border-status-red-danger bg-status-red-danger text-status-red-danger";
      }

      break;
  }
};

export const formattedLevelBattery = (level) => {
  if (level > 99) {
    return <i className="fas fa-battery-full"></i>;
  } else if (level === 0) {
    return <i className="fas fa-battery-empty"></i>;
  } else if (level > 50 && level <= 99) {
    return <i className="fas fa-battery-three-quarters"></i>;
  } else if (level > 30 && level <= 50) {
    return <i className="fas fa-battery-half"></i>;
  } else if (level > 10 && level <= 30) {
    return <i className="fas fa-battery-quarter"></i>;
  } else if (level > 5 && level <= 10) {
    return <i className="fas fa-battery-empty"></i>;
  } else if (level <= 5) {
    return <i className="fas fa-plug"></i>;
  }
};

export const formattedNetworkQuality = () => {
  return <FaSignal />;
};

export const formattedFillingDelivery = (
  type,
  categorie = "filling",
  language,
) => {
  let result = "";
  switch (type) {
    case "critical":
      if (categorie === "map") {
        result =
          language === "en"
            ? "Critical "
            : language === "es"
              ? "Elevado "
              : "Elevé ";
      } else {
        result =
          language === "en"
            ? "Critical "
            : language === "es"
              ? "Llenado Crítico "
              : "Remplissage critique ";
      }
      break;

    case "in_progress":
      if (categorie === "map") {
        result = "Normal ";
      } else {
        result =
          language === "en"
            ? "In progress "
            : language === "es"
              ? "En proceso de llenado "
              : "En cours de remplissage ";
      }
      break;

    case "empty":
      result =
        language === "en" ? "Empty " : language === "es" ? "Vacío " : "Vide ";
      break;

    case "light":
      result =
        language === "en" ? "Low " : language === "es" ? "Bajo " : "Faible ";
      break;

    case "inactive":
      result =
        language === "en"
          ? "Inactive "
          : language === "es"
            ? "Inactivo "
            : "Inactif ";
      break;

    case "critical_danger":
      result =
        language === "en"
          ? "Critical danger "
          : language === "es"
            ? "Muy alto "
            : "Très élevé ";
      break;

    case "all":
      result =
        language === "en" ? "All " : language === "es" ? "Todos " : "Tous ";
      break;

    default:
      break;
  }
  return result;
};

export const formattedFillingDeliveryColor = (type, categorie = "filling") => {
  let result = "";
  switch (type) {
    case "critical":
      result = "#FF1617";
      break;

    case "in_progress":
      if (categorie === "map") {
        result = "#FFBD59";
      } else {
        result = "#00A7A6";
      }
      break;

    case "empty":
      result = "#0F265C";
      break;

    case "light":
      result = "#7ED958";
      break;

    case "critical_danger":
      result = "#AC0001";
      break;

    case "inactive":
      result = "#DBEFBC";
      break;

    case "all":
      result = "#00A7A6";
      break;
    default:
      break;
  }
  return result;
};

export const formattedWasteColor = (waste) => {
  let result = "";
  switch (
    waste
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
  ) {
    case "incinerable":
      result = "#0F265C";
      break;

    case "gravats":
      result = "#00A7A6";
      break;

    case "vegetaux":
      result = "#6CB647";
      break;

    case "encombrant":
      result = "#F9AA00";
      break;

    case "tout-venant":
      result = "#F9AA00";
      break;

    case "carton":
      result = "#E8363B";
      break;

    case "dib":
      result = "#FF006E";
      break;

    case "ferraille a cisailler":
      result = "#48A9A6";
      break;

    case "ferraille e5m":
      result = "#935FA7";
      break;

    case "dechets ultimes":
      result = "#B23A48";
      break;

    case "ferraille":
      result = "#FAB82C";
      break;

    case "bois":
      result = "#2B4B9A";
      break;

    default:
      result = "#0F265C";
      break;
  }
  return result;
};

export const formattedContainerId = (id) => {
  let containerIdPrefix;
  if (id) {
    const strContainer = id.toString();
    const lenContainer = "#000";
    containerIdPrefix = lenContainer.substring(
      0,
      lenContainer.length - strContainer.length,
    );
  } else {
    containerIdPrefix = "...";
  }

  return containerIdPrefix;
};

export const toFrenchNumber = (number, decimalPlaces) => {
  return number?.toLocaleString("fr-FR", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

export const getShortIdPart = (id) => {
  const suffix = "_l";
  const suffixIndex = id.lastIndexOf(suffix);

  if (suffixIndex !== -1) {
    const idBeforeSuffix = id.slice(0, suffixIndex);
    return idBeforeSuffix.slice(-5);
  }

  return id;
};
