import React from "react";

function MaskLicenseAlert(args) {
  return (
    <div className="flex items-center fixed justify-center top-0 left-0 w-full h-full bg-[#FFFFFF] bg-opacity-75 z-20 pointer-events-auto">
      <div className="bg-[#00A7A6] text-white p-4 rounded-xl">
        <div className="flex flex-col">
          <span className="text-xl font-semibold">
            Accès temporairement limité
          </span>
          <span>
            Votre abonnement à notre service de suivi des déchets est arrivé à
            expiration.
          </span>
        </div>
        <div className="mt-3">
          <h6>
            Pensez à régulariser votre abonnement et continuez à bénéficier de :
          </h6>
          <ul class="list-disc ml-5">
            <li>
              <span className="font-semibold">Suivi en temps réel</span> des
              niveaux de remplissage de vos conteneurs
            </li>
            <li>
              <span className="font-semibold">
                Détection des erreurs de tri
              </span>{" "}
              pour améliorer la gestion des déchets
            </li>
            <li>
              <span className="font-semibold">
                Optimisation des tournées de collecte
              </span>
              , avec des camions planifiés pour des bennes pleines
            </li>
            <li>
              <span className="font-semibold">Rapports réglementaires</span> sur
              vos flux de déchets, prêts à être utilisés pour vos audits et
              obligations légales
            </li>
          </ul>
        </div>
        <div>
          <span className="underline underline-offset-4 text-white font-medium">
            Contactez-nous à{" "}
            <span className="font-semibold">client@akanthas.com</span> pour
            renouveler votre abonnement !
          </span>
        </div>
      </div>
    </div>
  );
}

export default MaskLicenseAlert;
