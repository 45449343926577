import { useTranslation } from "react-i18next";

const CampaignHeader = () => {
  const { t } = useTranslation();

  return (
    <article className="flex flex-row w-full bg-white mt-3 pt-2.5 pb-2 px-2 rounded-md">
      <h6 className="w-2/12 font-semibold">{t("Notification")}</h6>
      <h6 className="w-1/12 font-semibold">{t("Flux")}</h6>
      <h6 className="w-1/12 font-semibold">{t("Positioning")}</h6>
      <h6 className="w-2/12 font-semibold">{t("Treatment")}</h6>
      <h6 className="w-2/12 font-semibold">{t("Error type")}</h6>
      <h6 className="w-1/12 font-semibold">{t("Numbers")}</h6>
      <h6 className="w-1/12 font-semibold">{t("Removed")}</h6>
      <h6 className="w-2/12 font-semibold">{t("Reason for non-removal")}</h6>
    </article>
  );
};

export default CampaignHeader;
