export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function startWithLowercase(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function toMonthName(date, language) {
  const locales =
    language === "en" ? "en-EN" : language === "es" ? "es-ES" : "fr-FR";
  return date.toLocaleString(locales, { month: "long" });
}

export function currentMonthName(language) {
  const newDate = new Date();
  return toMonthName(newDate, language);
}

export function previousMonthName(language) {
  const newDate = new Date();
  newDate.setDate(1);
  newDate.setMonth(newDate.getMonth() - 1);
  return toMonthName(newDate, language);
}

export function getMonthName(value, language) {
  const newDate = new Date();
  newDate.setDate(1);
  newDate.setMonth(newDate.getMonth() - value);
  return toMonthName(newDate, language);
}
