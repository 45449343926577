import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Logo from "../../static/assets/logo_rvg.png";
import { useTranslation } from "react-i18next";
import LoginFooter from "./LoginFooter.js";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { requestFcmToken } from "../../firebase.js";

const Login = ({ setCookies, saveUser, API }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const continueButtonRef = useRef(null); // Reference for the first div
  const [googleButtonWidth, setGoogleButtonWidth] = useState(0); // Store the width of the first div

  useEffect(() => {
    // Get the width of the first div once the component is mounted
    if (continueButtonRef.current) {
      setGoogleButtonWidth(continueButtonRef.current.offsetWidth); // Set the width in pixels
    }

    const handleResize = () => {
      if (continueButtonRef.current) {
        setGoogleButtonWidth(continueButtonRef.current.offsetWidth); // Update width on window resize
      }
    };

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const GOOGLE_SSO_CLIENT_ID = process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID;

  const handleGoogleLoginSuccess = async (response) => {
    const token = response.credential;

    try {
      // Send the token to your backend for verification and processing
      const responseBackend = await axios.post(
        `${API}auth/google/callback`,
        { body: token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      // Process the backend response
      setCookies(
        responseBackend.data.token,
        responseBackend.data.firstName,
        responseBackend.data.email,
      );

      saveUser(responseBackend.data);
      setErrorMessage("");
      history.push("/");
    } catch (error) {
      setErrorMessage(error.response.data);
      console.error("Error logging in with Google:", error);
    }
  };

  useEffect(() => {
    const themeColor = document.querySelector('meta[name="theme-color"]');
    themeColor.setAttribute("content", "#f5f5f5"); // Couleur spécifique pour la page login
    // Nettoyage : remettre la couleur par défaut quand on quitte la page
    return () => {
      themeColor.setAttribute("content", "#00A7A6"); // Couleur par défaut quand on quitte
    };
  }, []); // Le tableau vide garantit que le code s'exécute uniquement au montage et démontage de la page

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const response = await axios.post(`${API}user/login`, {
        email: email,
        password: password,
      });

      setCookies(
        response.data.token,
        response.data.firstName,
        response.data.email,
      );
      saveUser(response.data);
      if ("Notification" in window) {
        if (
          Notification.permission === "granted" &&
          response?.data?.receiveAnomalyPush
        ) {
          await requestFcmToken();
        }
      }
      setErrorMessage("");
      history.push("/");
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  return (
    <div className="login-page">
      <div className="header">
        <img src={Logo} alt="Logo de la boîte" className="logo" />
      </div>
      <div className="login-container">
        <div className="login-box">
          <h1 className="sign-in-message">{t("Jump in!")}</h1>{" "}
          <p className="tagline">
            {t(
              "Together, we're not just managing waste, we're changing the world.",
            )}
          </p>
          <p className="tagline"></p>
          <form onSubmit={handleSubmit} className="login-form">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(event) => {
                const value = event.target.value;

                // Remove all spaces from the email input
                const cleanedValue = value.replace(/\s/g, ""); // Remove all spaces
                setEmail(cleanedValue);
                setErrorMessage("");
              }}
              className={errorMessage === "invalid_email" ? "error" : ""}
              required
            />
            {errorMessage === "invalid_email" && (
              <p className="error-message">{t("Invalid email address")}</p>
            )}
            <div className="password-field">
              <input
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                  setErrorMessage("");
                }}
                className="password-input"
                required
              />

              <button
                type="button"
                className="toggle-password-btn"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                <FontAwesomeIcon
                  icon={isPasswordVisible ? faEye : faEyeSlash}
                />
              </button>

              {errorMessage === "invalid_password" && (
                <p className="error-message">{t("Invalid password")}</p>
              )}
            </div>
            {errorMessage &&
              errorMessage !== "invalid_email" &&
              errorMessage !== "invalid_password" && (
                <p className="error-message global-error">{errorMessage}</p>
              )}
            <button
              onClick={() => history.push("/forgotpassword")}
              type="button"
              className="back-link"
            >
              {t("Forgot password?")}
            </button>
            <button
              ref={continueButtonRef}
              type="submit"
              className="login-button"
            >
              {t("Continue")}
            </button>

            <div className="divider">
              <span>{t("OR")}</span>
            </div>
            <GoogleOAuthProvider
              clientId={GOOGLE_SSO_CLIENT_ID}
              style={{ width: "100%" }}
            >
              <GoogleLogin
                onSuccess={handleGoogleLoginSuccess}
                onError={() => console.error("Login Failed")}
                width={googleButtonWidth}
              />
            </GoogleOAuthProvider>
          </form>
        </div>
      </div>

      <LoginFooter />
    </div>
  );
};

export default Login;
