import React from "react";
import { useTranslation } from "react-i18next";
import { FaLockOpen } from "react-icons/fa";
import EnglishSampleCharacterizarion from "../static/assets/sample-characterizarion-en.png";
import EnglishSampleWeight from "../static/assets/sample-weight-en.png";
import EnglishSampleReport from "../static/assets/sample-report-en.png";
import FrenchSampleCharacterizarion from "../static/assets/sample-characterizarion-fr.png";
import FrenchSampleWeight from "../static/assets/sample-weight-fr.png";
import FrenchSampleReport from "../static/assets/sample-report-fr.png";
import EnglishSampleContainers from "../static/assets/sample-containers-en.png";
import EnglishSampleActivity from "../static/assets/sample-activity-en.png";
import EnglishSampleCaracteristic from "../static/assets/sample-caracteristic-en.png";
import FrenchSampleContainers from "../static/assets/sample-containers-fr.png";
import FrenchSampleActivity from "../static/assets/sample-activity-fr.png";
import FrenchSampleCaracteristic from "../static/assets/sample-caracteristic-fr.png";
import campaignList from "../static/assets/campaign-list.png";
import campaignExport from "../static/assets/campaign-export.png";
import campaignMobile from "../static/assets/campaign-mobile.jpg";

const ForbiddenModule = ({ viewType }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-col">
      <section className="w-full flex flex-col bg-white p-3 rounded-lg my-2 items-center">
        <h3 className="flex justify-center text-center font-semibold w-2/3 ml-2 mt-3">
          <FaLockOpen style={{ marginRight: 3 }} />
          {viewType === "monitoring"
            ? t("Save 30% on your waste management costs")
            : viewType === "characterization"
              ? t(
                  "Generate regulatory characterization reports complying with AGEC Decree 1199",
                )
              : t(
                  "Optimize your sorting campaigns with precise and real-time tracking",
                )}
        </h3>
        <span>
          {t("Contact us at")}{" "}
          <span className="text-[#0F265C]">client@akanthas.com</span>{" "}
          {t("for more information")}.
        </span>
      </section>
      <section className="w-full flex flex-col bg-white p-3 rounded-lg my-2 items-center">
        {viewType !== "campaign" && (
          <div className="flex flex-col w-5/6 border-b items-center">
            <h1 className="w-full text-center font-bold text-[#F8AA00]">
              {viewType === "monitoring" ? "20" : "80"}%
            </h1>
            <span className="mb-4">
              {viewType === "monitoring"
                ? t(
                    "Improve the quality of your waste streams by reducing sorting errors by 20%",
                  )
                : t(
                    "Get your characterization reports at 80% less cost than traditional methods",
                  )}
            </span>
          </div>
        )}
        <div className="w-full flex flex-col items-center">
          <h3 className="text-center font-bold mt-4 mb-4">
            {viewType === "monitoring"
              ? t("Top waste monitoring features")
              : viewType === "characterization"
                ? t("Top characterization features")
                : t("Top sorting campaign features")}
          </h3>
          <div className="w-full flex flex-row gap-4">
            <div className="w-1/3 flex flex-col p-3 text-center">
              {viewType === "monitoring" ? (
                <>
                  <img
                    alt="img"
                    src={
                      i18n.language === "fr"
                        ? FrenchSampleContainers
                        : EnglishSampleContainers
                    }
                  />
                  <h5 className="text-center font-bold my-3">
                    {t("Real-Time Monitoring")}
                  </h5>
                  <span className="text-center px-2">
                    {t(
                      "Track the fill levels of your containers and storage cells in real time",
                    )}
                    . {t("All your information, all in one place")}.
                  </span>
                </>
              ) : viewType === "characterization" ? (
                <>
                  <img
                    alt="img"
                    src={
                      i18n.language === "fr"
                        ? FrenchSampleCharacterizarion
                        : EnglishSampleCharacterizarion
                    }
                  />
                  <h5 className="text-center font-bold my-3">
                    {t("Visualize Characterized Waste Categories")}
                  </h5>
                  <span className="text-center px-2">
                    {t(
                      "View your characterized waste streams and display the detected material types one by one",
                    )}
                    .
                  </span>
                </>
              ) : (
                <>
                  <img alt="img" src={campaignMobile} />
                  <h5 className="text-center font-bold my-3">
                    {t("Real-time sorting error notifications")}
                  </h5>
                  <span className="text-center px-2">
                    {t(
                      "Receive instant alerts on a mobile app, allowing operators to quickly correct errors and log actions performed",
                    )}
                    .
                  </span>
                </>
              )}
            </div>
            <div className="w-1/3 flex flex-col p-3">
              {viewType === "monitoring" ? (
                <>
                  <img
                    alt="img"
                    src={
                      i18n.language === "fr"
                        ? FrenchSampleActivity
                        : EnglishSampleActivity
                    }
                  />
                  <h5 className="text-center font-bold my-3">
                    {t("Quality Scoring & Downgrade Potential")}
                  </h5>
                  <span className="text-center px-2">
                    {t("Review quality scores and monitor downgrade risks")}.{" "}
                    {t(
                      "Access historical data on fill levels and sorting errors",
                    )}
                  </span>
                </>
              ) : viewType === "characterization" ? (
                <>
                  <img
                    alt="img"
                    src={
                      i18n.language === "fr"
                        ? FrenchSampleWeight
                        : EnglishSampleWeight
                    }
                  />
                  <h5 className="text-center font-bold my-3">
                    {t("Input Weighing Tickets")}
                  </h5>
                  <span className="text-center px-2">
                    {t(
                      "Enter weighing tickets and customize characterizations to your specific needs, including material flows, sorting challenges, comments, and producer names",
                    )}
                    .
                  </span>
                </>
              ) : (
                <>
                  <img alt="img" src={campaignList} />
                  <h5 className="text-center font-bold my-3">
                    {t("Centralized sorting actions tracking")}
                  </h5>
                  <span className="text-center px-2">
                    {t(
                      "View all interventions and sorting data in an intuitive dashboard designed for efficient performance monitoring",
                    )}
                    .
                  </span>
                </>
              )}
            </div>
            <div className="w-1/3 flex flex-col p-3">
              {viewType === "monitoring" ? (
                <>
                  <img
                    alt="img"
                    src={
                      i18n.language === "fr"
                        ? FrenchSampleCaracteristic
                        : EnglishSampleCaracteristic
                    }
                  />
                  <h5 className="text-center font-bold my-3">
                    {t("Automated Sorting Error Alerts & Collection Requests")}
                  </h5>
                  <span className="text-center px-2">
                    {t(
                      "Customize sorting error types and collection thresholds",
                    )}
                    .{" "}
                    {t(
                      "Automatically notify your team or service providers in real time",
                    )}
                    .
                  </span>
                </>
              ) : viewType === "characterization" ? (
                <>
                  <img
                    alt="img"
                    src={
                      i18n.language === "fr"
                        ? FrenchSampleReport
                        : EnglishSampleReport
                    }
                  />
                  <h5 className="text-center font-bold my-3">
                    {t("Export Regulatory Reports")}
                  </h5>
                  <span className="text-center px-2">
                    {t(
                      "Automatically export your characterization reports in the format required by regulations",
                    )}
                    .
                  </span>
                </>
              ) : (
                <>
                  <img alt="img" src={campaignExport} />
                  <h5 className="text-center font-bold my-3">
                    {t("Data export for reporting")}
                  </h5>
                  <span className="text-center px-2">
                    {t(
                      "Automate the export of sorting reports to easily share analyses and results with stakeholders",
                    )}
                    .
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForbiddenModule;
