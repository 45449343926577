import axiosInstance from "./axiosInstance";

export const getUserData = (id) => {
  return axiosInstance.get(`access/${id}`);
};

export const updateUserLanguage = (params, id) => {
  return axiosInstance.post(`access/language/${id}`, params);
};

export const updateDashboardConfiguration = (params, id) => {
  return axiosInstance.post(`access/dashboardConfiguration/${id}`, params);
};

export const updateUserFcmToken = (params) => {
  return axiosInstance.post(`/access/fcmToken`, params);
};

export const updateUserCorporation = (params, id) => {
  return axiosInstance.post(`access/corporation/${id}`, params);
};
