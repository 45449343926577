import { useEffect, useState } from "react";
import Loading from "../../../components/utils/Loading";
import { formattedDate } from "../../../components/formatted/FormattedDateAndTime";
import { useTranslation } from "react-i18next";
import TruckSvg from "../../../components/utils/TruckSvg";

const StatisticsKeyValuesComponent = ({
  periodHistoryStart,
  periodHistoryStop,
  periodHistoryStartPrevious,
  periodHistoryStopPrevious,
  numberOfContainers,
  numberOfSite,
  periodName,
  statisticsCollectionCurrent,
  statisticsCollectionPrevious,
  collectionWasteGenerated,
  isLoading,
}) => {
  const { t, i18n } = useTranslation();
  const [numberOfCollectionCurrent, setNumberOfCollectionCurrent] = useState(0);
  const [numberOfCollectionPrevious, setNumberOfCollectionPrevious] =
    useState(0);
  const [totalWasteVolume, setTotalWasteVolume] = useState(0);
  const [percentageCollection, setPercentageCollection] = useState(0);
  const [isCollectionsPreviousPeriod, setIsCollectionsPreviousPeriod] =
    useState(0);
  const [helpCollectionCurrent, setHelpCollectionCurrent] = useState(false);
  const [helpCollectionPrevious, setHelpCollectionPrevious] = useState(false);
  const [helpCollectionComparison, setHelpCollectionComparison] =
    useState(false);

  useEffect(() => {
    // //Response Collection Count
    setNumberOfCollectionCurrent(statisticsCollectionCurrent.countCollections);
    setNumberOfCollectionPrevious(
      statisticsCollectionPrevious.countCollections,
    );
    //Calcule du % d'augmentation ou de diminution d'anomalie : (valeur actuelle - valeur précédente) / valeur précédente * 100
    setPercentageCollection(
      Math.round(
        ((statisticsCollectionCurrent.countCollections -
          statisticsCollectionPrevious.countCollections) /
          statisticsCollectionPrevious.countCollections) *
          100,
      ),
    );

    setIsCollectionsPreviousPeriod(
      statisticsCollectionPrevious.countCollections > 0,
    );

    //Response Waste Generated
    setTotalWasteVolume(collectionWasteGenerated.totalVolumeMaterial);
  }, [
    statisticsCollectionCurrent,
    statisticsCollectionPrevious,
    collectionWasteGenerated,
  ]);

  return (
    <div>
      {!isLoading ? (
        <section className="bg-white p-3 rounded-lg my-2">
          <h4 className="font-semibold">
            {t("Summary of period")} : <span>{periodName} </span>
            <span>
              | {numberOfSite} site{numberOfSite > 1 ? "s " : " "}
            </span>
            <span>
              | {numberOfContainers}{" "}
              {t(
                numberOfContainers === 1
                  ? "Analysed area/container"
                  : "Analysed areas/containers",
              )}
            </span>
          </h4>

          <article className="flex flex-row w-full p-4 gap-6">
            <div className="flex flex-col w-1/4 p-3 bg-[#FFE2E5] rounded-lg">
              <div className="grid justify-items-center rounded-full bg-[#FA5A7D] h-8 w-8">
                <TruckSvg
                  color={"#FFF"}
                  width={20}
                  height={20}
                  className={"mx-1 my-auto"}
                />
              </div>
              <h3 className="font-bold mt-2">{numberOfCollectionCurrent}</h3>
              <div className="flex">
                <span>{t("Rotations completed")}</span>
                <i
                  className="fas fa-info-circle text-gainsboro text-xs ml-1"
                  onMouseOver={() => setHelpCollectionCurrent(true)}
                  onMouseOut={() => setHelpCollectionCurrent(false)}
                ></i>
              </div>
              {helpCollectionCurrent ? (
                <div
                  className="absolute bg-yellow text-white top-96 text-xs p-2 rounded"
                  style={{ left: "18rem" }}
                >
                  {i18n.language === "fr" && "Du "}
                  {formattedDate(periodHistoryStart, i18n.language)}{" "}
                  {i18n.language === "fr" ? "à" : "to"}{" "}
                  {formattedDate(periodHistoryStop, i18n.language)}
                </div>
              ) : null}
            </div>

            <div className="flex flex-col w-1/4 p-3 bg-[#DCFCE7] rounded-lg">
              <div className="grid justify-items-center rounded-full bg-[#3CD856] h-8 w-8">
                <TruckSvg
                  color={"#FFF"}
                  width={20}
                  height={20}
                  className={"mx-1 my-auto"}
                />
              </div>
              <h3 className="font-bold mt-2">{numberOfCollectionPrevious}</h3>
              <div className="flex">
                <span>{t("Previous period rotations")}</span>
                <i
                  className="fas fa-info-circle text-gainsboro text-xs ml-1"
                  onMouseOver={() => setHelpCollectionPrevious(true)}
                  onMouseOut={() => setHelpCollectionPrevious(false)}
                ></i>
              </div>
              {helpCollectionPrevious ? (
                <div
                  className="absolute bg-yellow text-white top-96 text-xs p-2 rounded"
                  style={{ left: "38rem" }}
                >
                  {i18n.language === "fr" && "Du "}
                  {formattedDate(
                    periodHistoryStartPrevious,
                    i18n.language,
                  )}{" "}
                  {i18n.language === "fr" ? "au" : "to"}{" "}
                  {formattedDate(periodHistoryStopPrevious, i18n.language)}
                </div>
              ) : null}
            </div>

            <div className="flex flex-col w-1/4 p-3 bg-[#FFF4DE] rounded-lg">
              <div className="grid justify-items-center rounded-full bg-[#FF947A] h-8 w-8">
                <i
                  className="fas fa-eye text-white my-auto"
                  style={{ marginTop: "2px" }}
                ></i>
              </div>
              <h3 className="font-bold mt-2">
                {Math.round(totalWasteVolume)} m<sup>3</sup>
              </h3>
              <div className="flex">
                <span>{t("Analyzed waste")}</span>
              </div>
            </div>
            {isCollectionsPreviousPeriod ? (
              <div className="flex flex-col w-1/4 p-3 bg-[#F3E8FF] rounded-lg">
                <div className="grid justify-items-center rounded-full bg-[#BF83FF] h-8 w-8">
                  <i
                    className="fas fa-chart-bar text-white my-auto"
                    style={{ marginTop: "2px" }}
                  ></i>
                </div>
                <h3 className="font-bold mt-2">
                  {percentageCollection > 0 ? "+" : null}
                  {percentageCollection}%
                </h3>
                <div className="flex">
                  <span>{t("Rotation variations")}</span>
                  <i
                    className="fas fa-info-circle text-gainsboro text-xs ml-1"
                    onMouseOver={() => setHelpCollectionComparison(true)}
                    onMouseOut={() => setHelpCollectionComparison(false)}
                  ></i>
                  {helpCollectionComparison && (
                    <div
                      className="absolute bg-yellow text-white top-96 text-xs p-2 rounded"
                      style={{ right: "10rem" }}
                    >
                      {t("Comparison with the previous period")}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="flex flex-col w-1/4 p-3 text-center bg-[#F3E8FF] rounded-lg">
                <i className="fas fa-exclamation-triangle text-xl mt-3"></i>
                <span className="text-center">
                  {t("Not enough data on the chosen period")}.
                </span>
              </div>
            )}
          </article>
        </section>
      ) : (
        <div className="my-2">
          <Loading height={"35vh"} />
        </div>
      )}
    </div>
  );
};

export default StatisticsKeyValuesComponent;
