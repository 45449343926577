import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import Can from "./Can.js";
import {
  MdAccountCircle,
  MdCameraAlt,
  MdExitToApp,
  MdPinDrop,
  MdHelp,
} from "react-icons/md";
import { ListGroup, ListGroupItem } from "reactstrap";
import logoHeader from "../static/assets/logoHeader.png";
import { formattedRole } from "./formatted/FormattedText.js";
import {
  updateUserCorporation,
  updateUserLanguage,
} from "../services/users.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserLanguage } from "../redux/languageSlice.js";
import { languageData } from "../shared/utils/utils.js";
import { useTranslation } from "react-i18next";

import { FaLock } from "react-icons/fa";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
library.add(faTimes);

const Header = ({ setCookies, saveUser, userData, licenseExpired }) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [openTranslation, setOpenTranslation] = useState(false);
  const [activeCorporation, setActiveCorporation] = useState(null);
  const [multiCorporations, setMultiCorporations] = useState([]);
  const [openMultiCorporations, setOpenMultiCorporations] = useState(false);
  const language = useSelector((state) => state.language.data);
  const dispatch = useDispatch();
  const languageRef = useRef(null);
  const corporationRef = useRef(null);
  const userRef = useRef(null);
  const history = useHistory();
  const [logo, setLogo] = useState(null);

  function close() {
    setIsOpen(false);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (languageRef.current && !languageRef.current.contains(event.target)) {
        setOpenTranslation(false);
      }
      if (userRef.current && !userRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (
        corporationRef.current &&
        !corporationRef.current.contains(event.target)
      ) {
        setOpenMultiCorporations(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getLogo = (filename) => {
    try {
      return require(`../static/assets/${filename}.png`);
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    if (userData && userData.corporation) {
      const logoFilename = userData.corporation;
      if (
        userData?.multiCorporations &&
        userData.multiCorporations.length > 1
      ) {
        const corporation = userData.multiCorporations.filter(
          (corporation) => corporation._id === userData.corporation,
        )[0];
        setActiveCorporation(corporation);
      }
      const loadedLogo = getLogo(logoFilename);
      setLogo(loadedLogo);
      if (
        userData?.multiCorporations &&
        userData.multiCorporations.length > 1
      ) {
        const corporations = userData.multiCorporations.map((corporation) => {
          return {
            value: corporation._id,
            label: corporation.corporationName,
            logo: getLogo(corporation._id),
          };
        });
        setMultiCorporations(corporations);
      }
    }
  }, [userData]);

  const changeCorporation = async (value) => {
    setOpenMultiCorporations(!openMultiCorporations);
    await updateUserCorporation({ corporation: value }, userData._id)
      .then(async (response) => {
        await saveUser(response.data);
        window.location.reload(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const changeLanguage = async (item) => {
    setOpenTranslation(!openTranslation);
    await updateUserLanguage({ language: item.value }, userData._id)
      .then(() => {
        localStorage.setItem("language", item.value);
        dispatch(fetchUserLanguage(userData._id));
        i18n.changeLanguage(item.value);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <header className="header">
      <section className="header-wrapper flex flex-row items-center gap-4">
        <article className="z-30">
          {licenseExpired === true && (
            <FaLock
              style={{ marginRight: 15, color: "#0F265C", fontSize: 18 }}
            />
          )}
        </article>
        {multiCorporations.length > 1 ? (
          <article className="z-30">
            <div
              onClick={() => setOpenMultiCorporations(true)}
              className="w-80 bg-white rounded px-2 text-[#828899] cursor-pointer"
            >
              {activeCorporation && (
                <div
                  className={`flex flex-row gap-2 items-center justify-between ${!logo ? "py-1" : ""}`}
                  key={activeCorporation._id}
                >
                  <div className="w-5/6 flex flex-row gap-1 items-center justify-between">
                    <span>{activeCorporation.corporationName}</span>
                    {logo !== null && (
                      <article
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          style={{
                            maxWidth: 80,
                            maxHeight: 40,
                            marginRight: "30px",
                          }}
                          src={logo}
                          alt="Your Logo"
                        />
                      </article>
                    )}
                  </div>
                  <i className="fas fa-angle-down"></i>
                </div>
              )}
            </div>
            <div
              className={`absolute flex flex-col gap-2 w-64 bg-white border-hidden rounded mt-2 py-1 ${openMultiCorporations ? "visible" : "hidden"}`}
              ref={corporationRef}
            >
              {multiCorporations.map((corporation) => (
                <div
                  key={corporation.value}
                  className="flex flex-row gap-2 px-2 items-center justify-between cursor-pointer hover:bg-gray-100"
                  onClick={() => changeCorporation(corporation.value)}
                >
                  <span>{corporation.label}</span>
                </div>
              ))}
            </div>
          </article>
        ) : (
          logo !== null && (
            <article style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{
                  maxWidth: 80,
                  maxHeight: 40,
                  marginRight: "30px",
                }}
                src={logo}
                alt="Your Logo"
              />
            </article>
          )
        )}

        <article style={{ marginRight: "20px" }}>
          <div
            class="w-5 h-5 rounded-full cursor-pointer"
            onClick={() => {
              setOpenTranslation(!openTranslation);
            }}
          >
            <img src={language?.flag} alt="flag" />
          </div>
          <article
            className={`header-user-dialog flex flex-col gap-2 w-40 bg-white border-hidden mt-2 ${openTranslation ? "visible" : "hidden"}`}
            ref={languageRef}
          >
            {languageData.map((item) => (
              <div
                key={item._id}
                className="flex flex-row gap-2 px-2 items-center cursor-pointer hover:bg-gray-100"
                onClick={() => changeLanguage(item)}
              >
                <img className="w-4 h-4" src={item.flag} alt="flag" />
                <span>{item.label}</span>
              </div>
            ))}
          </article>
        </article>

        <article
          className="z-30"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <button className="header-button">
            <img className="logoHeader" src={logoHeader} alt="logo" />
          </button>
          <article
            className={`absolute z-30 header-user-dialog ${isOpen ? "visible" : "hidden"}`}
            ref={userRef}
          >
            <div className="header-user-info-top">
              <div className="header-user-info-text">
                {userData?.firstName + "\n " + userData?.lastName}
                <br />
                <div className="header-user-info-text2">
                  {formattedRole(userData?.role, userData?.admin)}
                </div>
              </div>

              <FontAwesomeIcon
                className="header-user-close"
                icon="times"
                color="white"
                onClick={() => setIsOpen(false)}
              />
            </div>

            <ListGroup flush>
              <Can
                role={userData?.role}
                perform="navbar:access"
                yes={() => (
                  <ListGroupItem
                    tag="button"
                    action
                    className=" d-flex align-items-center border-0"
                    onClick={() => {
                      close();
                      history.push("/access");
                    }}
                  >
                    <MdAccountCircle /> &nbsp;&nbsp;{t("Access")}
                  </ListGroupItem>
                )}
              />

              <Can
                role={userData?.role}
                perform="navbar:address"
                yes={() => (
                  <ListGroupItem
                    tag="button"
                    action
                    className=" d-flex align-items-center border-0"
                    onClick={() => {
                      close();
                      history.push("/address");
                    }}
                  >
                    <MdPinDrop /> &nbsp;&nbsp;{t("Address")}
                  </ListGroupItem>
                )}
              />

              <ListGroupItem
                tag="button"
                action
                className=" d-flex align-items-center border-0"
                onClick={() => {
                  close();
                  history.push("/cameras");
                }}
              >
                <MdCameraAlt /> &nbsp;&nbsp;{t("Cameras")}
              </ListGroupItem>

              <ListGroupItem
                tag="button"
                action
                className=" d-flex align-items-center border-0"
                onClick={() => {
                  close();
                  history.push("/help");
                }}
              >
                <MdHelp /> &nbsp;&nbsp;{t("Help and Contact")}
              </ListGroupItem>

              <ListGroupItem
                className=" d-flex align-items-center border-0"
                tag="button"
                action
                onClick={async () => {
                  await setCookies(null, null, null);
                  await saveUser(null);
                  localStorage.clear();
                  sessionStorage.clear();
                  history.push("/login");
                }}
              >
                <MdExitToApp /> &nbsp;&nbsp;{t("Log out")}
              </ListGroupItem>
            </ListGroup>
          </article>
        </article>
      </section>
    </header>
  );
};

export default Header;
