import React from "react";
import AppHeaderBar from "./AppHeaderBar";
import AppNavBar from "./AppNavBar";
import { useHistory } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import { updateUserFcmToken } from "../services/users";
import { useTranslation } from "react-i18next";

const AppAccount = ({ setCookies, saveUser }) => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user")) || null;
  const { t } = useTranslation();

  const handleLogout = async () => {
    await updateUserFcmToken({
      fcmToken: "",
      receiveAnomalyPush: user?.receiveAnomalyPush || false,
    });
    await setCookies(null, null, null);
    await saveUser(null);
    localStorage.clear();
    sessionStorage.clear();
    history.push("/login");
  };

  const styles = {
    container: {
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    button: {
      display: "flex",
      alignItems: "center",
      padding: "10px 20px",
      backgroundColor: "#ff4d4f",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      fontSize: "16px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },
    buttonIcon: {
      marginRight: "10px",
      fontSize: "20px",
    },
    buttonText: {
      fontWeight: "bold",
    },
  };

  return (
    <div>
      <AppHeaderBar title="Akanthas" />
      <div className="mobileapp-container">
        <h1>{t("Account")}</h1>
        <button style={styles.button} onClick={handleLogout}>
          <FaSignOutAlt style={styles.buttonIcon} />
          <span style={styles.buttonText}>{t("Logout")}</span>
        </button>
      </div>
      <AppNavBar />
    </div>
  );
};

export default AppAccount;
