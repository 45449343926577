import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { currentMonthName } from "../../components/utils/Date";
import SkeletonLoader from "../../components/utils/SkeletonLoader";
import { getWasteStatistic } from "../../services/container";
import { AnomalyPieChart } from "../../components/charts/AnomalyPieChart";
import { getWasteCategoryColor } from "../../components/utils/WasteCategoryColor";
import CustomPopover from "../../components/popover/CustomPopover";

export const DashboardGaugeSortingErrors = ({
  countAnomalies,
  setCountAnomalies,
  setWasteErrorDelivery,
  datasetsWasteErrorDelivery,
  wasteCategory,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedWaste, setSelectedWaste] = useState(null);
  const { t, i18n } = useTranslation();
  const [loadAnomalies, setLoadAnomalies] = useState(true);
  const [help, setHelp] = useState(false);

  const handlePopoverOpen = (event, waste) => {
    setAnchorEl(event.currentTarget);
    setSelectedWaste(waste);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedWaste(null);
  };

  const fetchAnomalies = useCallback(async () => {
    const stopPeriod = new Date();
    const startPeriod = new Date(
      stopPeriod.getFullYear(),
      stopPeriod.getMonth(),
      1,
    );
    await getWasteStatistic({
      periodStart: startPeriod.toISOString(),
      periodStop: stopPeriod.toISOString(),
    }).then((response) => {
      if (response.status === 200) {
        setCountAnomalies(response.data.statisticsData.countAnomalies);
        setWasteErrorDelivery(response.data.statisticsData.wasteErrorDelivery);
      }
    });
    setLoadAnomalies(false);
  }, [setCountAnomalies, setWasteErrorDelivery]);

  useEffect(() => {
    fetchAnomalies();
  }, [fetchAnomalies]);

  return (
    <section className="dashboard-card">
      <article className="dashboard-card-top">
        <div className="dashboard-indicator">
          <article>
            <i
              className="fas fa-recycle "
              style={{
                fontSize: "55px",
                color: "var(--akanthas-cyan)",
                marginLeft: -10,
                marginTop: 8,
              }}
            ></i>
            <div className="flex flex-col ml-3 mt-1">
              <div className="flex flex-row">
                <h3
                  className="text-blush text-3xl font-semibold"
                  style={{ marginTop: -2 }}
                >
                  {countAnomalies}
                </h3>
                <h5 className="font-semibold text-lg mt-1.5 ml-2">
                  {t("sorting errors")}
                </h5>
              </div>
              <h5 className="font-semibold text-lg" style={{ marginTop: -13 }}>
                {t("on")}{" "}
                <span className="text-blush">
                  {currentMonthName(i18n.language)}
                </span>
              </h5>
            </div>
          </article>
        </div>
        <i
          className="fas fa-info-circle text-gainsboro"
          style={{ marginTop: "10px", marginRight: "-5px" }}
          onMouseOver={() => setHelp(true)}
          onMouseOut={() => setHelp(false)}
        ></i>
      </article>
      {loadAnomalies ? (
        <SkeletonLoader />
      ) : (
        <>
          <article className="dashboard-card-content">
            <div className="flex">
              <div style={{ flex: "1" }}>
                {datasetsWasteErrorDelivery.length > 0 &&
                  wasteCategory?.length > 0 && (
                    <AnomalyPieChart
                      dataChart={datasetsWasteErrorDelivery}
                      countAnomalies={countAnomalies}
                      wasteCategory={wasteCategory}
                    />
                  )}
              </div>
              <div
                className="flex flex-col"
                style={{
                  marginLeft: "5%",
                  width: "auto",
                  flex: "1",
                  overflow: "hidden",
                }}
              >
                {datasetsWasteErrorDelivery?.map((waste, index) => {
                  return (
                    <Link
                      to={{
                        pathname: "/statistics",
                        state: {
                          activateCollectionField: false,
                          activateQualityField: true,
                          activateCostField: false,
                          activateCarbonField: false,
                          keywordFilter: {
                            value: [
                              {
                                key: waste.label,
                                cat: "Flux",
                                type: "waste",
                                name: waste.label,
                              },
                            ],
                          },
                        },
                      }}
                      key={`${waste}-delivery-${waste._id}`}
                      onMouseEnter={(event) =>
                        handlePopoverOpen(event, waste?.label)
                      }
                      onMouseLeave={handlePopoverClose}
                      style={{
                        width: "100%",
                      }}
                    >
                      <div className="flex relative flex-row items-center justify-between w-ful">
                        <span
                          style={{
                            width: "9px",
                            height: "9px",
                            backgroundColor: `${getWasteCategoryColor(wasteCategory, waste)}`,
                            marginTop: 5,
                          }}
                        ></span>
                        <div
                          style={{
                            flex: "1",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            marginLeft: "5px",
                          }}
                        >
                          <span
                            className="underline"
                            style={{ fontSize: "11.5px" }}
                          >
                            {waste?.label}
                          </span>
                          <CustomPopover
                            open={open}
                            anchorEl={anchorEl}
                            content={selectedWaste}
                            onClose={handlePopoverClose}
                          />
                        </div>
                        <div style={{ width: 45, textAlign: "right" }}>
                          <span
                            className="ml-1"
                            style={{
                              fontSize: "11.5px",
                              marginTop: 4,
                            }}
                          >
                            (
                            {countAnomalies > 0
                              ? (
                                  (waste.data[0] * 100) /
                                  countAnomalies
                                ).toFixed(1)
                              : 0}
                            %)
                          </span>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </article>

          <article className="dashboard-card-footer">
            <Link
              to={{
                pathname: "/statistics",
                state: {
                  activateCollectionField: false,
                  activateQualityField: true,
                  activateCostField: false,
                  activateCarbonField: false,
                },
              }}
            >
              <div className="dashboard-text-underline">
                {t("View details")}
              </div>
            </Link>
          </article>

          {help ? (
            <article className="modal-item-key-help">
              {t(
                "Number of sorting errors detected across all containers during",
              )}{" "}
              {currentMonthName(i18n.language)}.
            </article>
          ) : null}
        </>
      )}
    </section>
  );
};
