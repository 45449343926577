import React, { useEffect, useRef, useState } from "react";
import AppHeaderBar from "./AppHeaderBar";
import AppNavBar from "./AppNavBar";
import { requestPermission } from "../firebase";
import Cookies from "js-cookie";
import NotificationsIcon from "@mui/icons-material/Notifications";
import TranslateIcon from "@mui/icons-material/Translate";
import { LoadingScreen } from "../components/loader/LoadingScreen";
import { updateUserFcmToken, updateUserLanguage } from "../services/users";
import { CustomSwitch } from "../components/CustomSwitch";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserLanguage } from "../redux/languageSlice.js";
import { languageData } from "../shared/utils/utils.js";
import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";

const AppSettings = () => {
  const user = JSON.parse(localStorage.getItem("user")) || null;
  const { t, i18n } = useTranslation();
  const [switchValue, setSwitchValue] = useState(
    user.receiveAnomalyPush ? true : false,
  );
  const [loading, setLoading] = useState(false);
  const [openTranslation, setOpenTranslation] = useState(false);
  const [osInfo, setOsInfo] = useState({});
  const language = useSelector((state) => state.language.data);
  const dispatch = useDispatch();
  const languageRef = useRef(null);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const getiOSInfo = () => {
      let isIOS = false;
      let osVersion = "Unknown";
      if (/iPhone|iPad|iPod/.test(userAgent)) {
        isIOS = true;
        const iosVersionMatch = userAgent.match(/OS (\d+_\d+)/);
        if (iosVersionMatch) {
          osVersion = iosVersionMatch[1].replace("_", ".");
        }
      }

      return { isIOS, osVersion };
    };

    setOsInfo(getiOSInfo());
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (languageRef.current && !languageRef.current.contains(event.target)) {
        setOpenTranslation(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const changeLanguage = async (item) => {
    setLoading(true);
    setOpenTranslation(!openTranslation);
    await updateUserLanguage({ language: item.value }, user._id)
      .then(() => {
        localStorage.setItem("language", item.value);
        dispatch(fetchUserLanguage(user._id));
        i18n.changeLanguage(item.value);
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  };

  const handleChange = async (event) => {
    setSwitchValue(event.target.checked);
    if (event.target.checked) {
      await requestPermission(setLoading);
    } else {
      setLoading(true);
      const userAccess = await updateUserFcmToken({
        fcmToken: "",
        receiveAnomalyPush: false,
      });
      localStorage.setItem("user", JSON.stringify(userAccess.data));
      Cookies.remove("fcmToken");
    }
    setLoading(false);
  };

  return (
    <div>
      <AppHeaderBar title={t("Settings")} />
      <div className="mobileapp-container">
        <h1>{t("Settings")}</h1>
        <div className="flex flex-col w-full gap-1 p-1 bg-white rounded-lg">
          <div className="flex justify-between items-center w-full py-2 border-b-1">
            <div className="flex justify-start">
              <TranslateIcon className="mr-1 text-[#6b7280]" />
              <span>{t("Language")}</span>
            </div>
            <article className="flex justify-end">
              <div
                class="flex flex-row items-center gap-2 cursor-pointer pr-2"
                onClick={() => {
                  setOpenTranslation(!openTranslation);
                }}
              >
                <span>{language?.label}</span>
                <i class="fas fa-chevron-right text-[#6b7280]"></i>
              </div>
              <article
                className={`header-user-dialog flex flex-col gap-2 w-40 bg-white border-hidden mt-24 ${openTranslation ? "visible" : "hidden"}`}
                ref={languageRef}
              >
                {languageData.map((item) => (
                  <div
                    key={item._id}
                    className="flex flex-row gap-2 px-2 items-center cursor-pointer hover:bg-gray-100"
                    onClick={() => changeLanguage(item)}
                  >
                    <img className="w-4 h-4" src={item.flag} alt="flag" />
                    <span>{item.label}</span>
                  </div>
                ))}
              </article>
            </article>
          </div>
          <div className="flex justify-between items-center w-full">
            <div className="flex justify-start">
              <NotificationsIcon className="mr-1 text-[#6b7280]" />
              <span>{t("Enable push notification")}</span>
            </div>
            <CustomSwitch
              className="flex justify-end"
              sx={{ m: 1 }}
              checked={switchValue}
              onChange={handleChange}
            />
          </div>
          {osInfo?.isIOS &&
          osInfo?.osVersion &&
          parseInt(osInfo?.osVersion) < 16.4 ? (
            <Alert className="flex flex-col" color="danger">
              <span className="text-xs">
                La version de votre navigateur ne prend pas en charge les
                notifications push. Veuillez le mettre à jour!
              </span>
            </Alert>
          ) : (
            ""
          )}
        </div>
      </div>
      {loading && <LoadingScreen />}
      <AppNavBar />
    </div>
  );
};

export default AppSettings;
