import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ConstructionIcon from "@mui/icons-material/Construction";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsIcon from "@mui/icons-material/Notifications";
import TuneIcon from "@mui/icons-material/Tune";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotification } from "../redux/notificationSlice";
import { useTranslation } from "react-i18next";

const AppNavBar = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notification.data);
  const { t } = useTranslation();
  const pathSegments = location.pathname.split("/");
  const activeIcon = pathSegments[2];
  const hoursAgo = process.env.REACT_APP_NOTIFICATION_HOURS_AGO;

  const handleNavigation = (icon) => {
    if (icon === "home") {
      history.push("/app/home");
    }
    if (icon === "installation") {
      history.push("/app/installation");
    }
    if (icon === "notifications") {
      history.push("/app/notifications");
    }
    if (icon === "settings") {
      history.push("/app/settings");
    }
  };

  useEffect(
    () => {
      dispatch(fetchNotification(hoursAgo));
    },
    // eslint-disable-next-line
    [history, dispatch],
  );

  return (
    <div className="bottom-nav-bar">
      <div
        className={`icon-container ${activeIcon === "home" ? "active" : ""}`}
        onClick={() => handleNavigation("home")}
      >
        <HomeIcon />
        <p className="icon-text">{t("Home")}</p>
      </div>

      <div
        className={`icon-container ${activeIcon === "notifications" ? "active" : ""}`}
        onClick={() => handleNavigation("notifications")}
      >
        <div className="notifications-icon-wrapper">
          <NotificationsIcon />
          {notifications.length > 0 && (
            <span className="notification-badge">{notifications.length}</span>
          )}
        </div>
        <p className="icon-text">{t("Notifications")}</p>
      </div>

      <div
        className={`icon-container ${activeIcon === "installation" || activeIcon === "installationdetails" ? "active" : ""}`}
        onClick={() => handleNavigation("installation")}
      >
        <ConstructionIcon />
        <p className="icon-text">{t("Installation")}</p>
      </div>

      <div
        className={`icon-container ${activeIcon === "settings" ? "active" : ""}`}
        onClick={() => handleNavigation("settings")}
      >
        <TuneIcon />
        <p className="icon-text">{t("Settings")}</p>
      </div>
    </div>
  );
};

export default AppNavBar;
