import React, { useEffect, useState } from "react";
import { WebMercatorViewport } from "@deck.gl/core";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { divIcon, point } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import MarkerClusterGroup from "react-leaflet-markercluster";
import {
  formattedFillingDelivery,
  formattedFillingDeliveryColor,
  formattedLevelColor,
} from "../formatted/FormattedText";
import { Link } from "react-router-dom";
import ContainerVisual from "../formatted/LoadImages";
import { Box, Grid } from "@mui/material";
import "./../../static/css/map.css";
import { CropImage } from "./ImageTreatment";
import {
  formattedDateDocument,
  formattedDayName,
  formattedTime,
} from "../formatted/FormattedDateAndTime";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const applyToArray = (func, array) => func.apply(Math, array);

const getBoundsForPoints = (points) => {
  // Calculate corner values of bounds

  if (points.length > 0) {
    let pointsLong = points.map((point) => point?.location?.[1]);
    let pointsLat = points.map((point) => point?.location?.[0]);

    pointsLong = pointsLong.filter(function (element) {
      return element !== undefined && element !== 0;
    });
    pointsLat = pointsLat.filter(function (element) {
      return element !== undefined && element !== 0;
    });

    if (pointsLong.length === 0 || pointsLat.length === 0) {
      const longitude = 1.4437;
      const latitude = 43.6043;
      const zoom = 10;
      return { longitude, latitude, zoom };
    }

    const cornersLongLat = [
      [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
      [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
    ];

    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({
      width: 1000,
      height: 1000,
    }).fitBounds(cornersLongLat, { padding: 150 }); // Can also use option: offset: [0, -100]
    const { latitude, longitude, zoom } = viewport;
    return { latitude, longitude, zoom };
  } else {
    const latitude = 43.6043;
    const longitude = 1.4437;
    const zoom = 30;
    return { longitude, latitude, zoom };
  }
};

const MapDisplay = ({
  fillingRateDelivery,
  markers,
  mapHeight,
  isMapOverlayActive,
  setIsMapZoomOverlayActive,
  setContainerActive,
  scrollToContainer,
  isActivity,
  cleanFilters,
  isContainer,
  fullData,
  orderSort,
  setCountData,
  setData,
}) => {
  const { t, i18n } = useTranslation();
  const bounds = getBoundsForPoints(markers);
  const [statusFilling, setStatusFilling] = useState("all");
  const [key, setKey] = useState(0);
  const imageWidth = 100;

  const handleMarkerClick = (point) => {
    setContainerActive(point);
    scrollToContainer(point._id);
  };

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [markers]);

  const filterByFilling = async (fullDataIn, status) => {
    let filteredData = fullDataIn;
    if (status === "all") {
      filteredData = fullDataIn;
    }

    if (status === "light") {
      filteredData = filteredData.filter((item) => {
        return (
          0 <= item.filling_level &&
          item.filling_level <= item.limitFillingLevel / 4
        );
      });
    }

    if (status === "in_progress") {
      filteredData = filteredData.filter((item) => {
        return (
          item.limitFillingLevel / 4 < item.filling_level &&
          item.filling_level < item.limitFillingLevel
        );
      });
    }

    if (status === "critical") {
      filteredData = filteredData.filter((item) => {
        return (
          item.limitFillingLevel <= item.filling_level &&
          item.filling_level <
            item.limitFillingLevel + (item.limitFillingLevel * 5) / 100
        );
      });
    }

    if (status === "critical_danger") {
      filteredData = filteredData.filter((item) => {
        return (
          item.limitFillingLevel + (item.limitFillingLevel * 5) / 100 <=
          item.filling_level
        );
      });
    }

    const compareAscending = (a, b) => {
      if (a["filling_level"] < b["filling_level"]) return -1;
      if (a["filling_level"] > b["filling_level"]) return 1;
      return 0;
    };

    const compareDescending = (a, b) => {
      if (a["filling_level"] > b["filling_level"]) return -1;
      if (a["filling_level"] < b["filling_level"]) return 1;
      return 0;
    };

    let orderFunction;
    if (orderSort === "1") {
      orderFunction = compareAscending;
    } else {
      orderFunction = compareDescending;
    }

    filteredData = filteredData.sort(orderFunction);
    setCountData(filteredData.length);
    setData([...filteredData]);
  };

  return (
    <div key={key} className="map-view z-20" style={{ height: mapHeight }}>
      {isMapOverlayActive ? (
        <div className="map-view-legend w-full flex flex-col py-1 px-1 bg-transparent">
          <div
            className="w-6 h-6 bg-gray-400 ml-auto flex items-center justify-center rounded-full"
            onClick={() => {
              setIsMapZoomOverlayActive(false);
              setContainerActive({});
            }}
          >
            <CloseIcon
              style={{
                width: 20,
                cursor: "pointer",
                color: "#FFF",
              }}
            />
          </div>
        </div>
      ) : isActivity ? null : (
        <section className="map-view-legend">
          <Box sx={{ flexGitem: 1 }}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <h6 className="data-bold text-base">{t("Filling rate")}</h6>
              </Grid>
              <Grid item>
                <div
                  className="map-view-legend-item"
                  onClick={() => {
                    setStatusFilling("all");
                    if (isContainer) {
                      filterByFilling(fullData, "all");
                    }
                  }}
                  style={{
                    opacity: statusFilling !== "all" ? "0.5" : "1",
                  }}
                >
                  <div
                    className="map-view-legend-circle"
                    style={{
                      backgroundColor: formattedFillingDeliveryColor(
                        "all",
                        "map",
                      ),
                    }}
                  ></div>
                  <span>{`${formattedFillingDelivery("all", "map", i18n.language)}(${isContainer ? fullData.length : markers.length})`}</span>
                </div>
              </Grid>
              {fillingRateDelivery.map((fillingRate, index) => (
                <Grid item key={fillingRate._id}>
                  <div
                    key={`filling-rate-${fillingRate._id}`}
                    className="map-view-legend-item"
                    onClick={() => {
                      setStatusFilling(fillingRate.type);
                      if (isContainer) {
                        filterByFilling(fullData, fillingRate.type);
                      }
                    }}
                    style={{
                      opacity:
                        statusFilling !== "all" &&
                        statusFilling !== fillingRate.type
                          ? "0.5"
                          : "1",
                    }}
                  >
                    <div
                      className="map-view-legend-circle"
                      style={{
                        backgroundColor: formattedFillingDeliveryColor(
                          fillingRate.type,
                          "map",
                        ),
                      }}
                    ></div>
                    <span>
                      {`${formattedFillingDelivery(fillingRate.type, "map", i18n.language)}(${fillingRate.value})`}
                    </span>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
        </section>
      )}

      <MapContainer
        style={{ height: mapHeight }}
        center={[bounds.latitude, bounds.longitude]}
        zoom={bounds.zoom}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup
          iconCreateFunction={function (cluster) {
            const markersCount = cluster.getChildCount();
            const html = `${markersCount}`;
            return divIcon({
              html: html,
              className: "map-icon-cluster",
              iconSize: point(32, 32),
            });
          }}
        >
          {markers.map((point, index) => {
            return point?.location &&
              (statusFilling === "all" ||
                statusFilling === point.statusFilling) ? (
              <Marker
                key={`marker-position-${point._id}`}
                position={[point.location[0], point.location[1]]}
                icon={divIcon({
                  html: renderToStaticMarkup(
                    <i
                      className={`fa fa-circle fa-2x map-icon-marker ${formattedLevelColor(point.filling_level, point.limitFillingLevel)}`}
                    />,
                  ),
                  className: "leaflet-div-icon-map",
                })}
                eventHandlers={{
                  click: () => {
                    if (isMapOverlayActive || isContainer) {
                      handleMarkerClick(point);
                    }
                  },
                }}
              >
                {!isMapOverlayActive && !isContainer && (
                  <Popup>
                    <div className="map-popup">
                      <div className="popup-header">
                        <h6 className="header-text">{point?.addressName}</h6>
                      </div>
                      <div className="indicator">
                        <span className="indicator-overflow">
                          Remplissage{" "}
                          <span
                            style={{ color: "var(--blush)" }}
                            className="data-bold"
                          >
                            {point.filling_level}%
                          </span>{" "}
                          |{" "}
                          <span
                            style={{ color: "var(--blush)" }}
                            className="data-bold"
                          >
                            {point.wasteName}
                          </span>{" "}
                          | {point.volumeToCubicMeter}m<sup>3</sup>
                        </span>
                        <span className="indicator-overflow">
                          <span
                            style={{ color: "var(--blush)" }}
                            className="data-bold"
                          >
                            {formattedDayName(point._time)}{" "}
                            {formattedDateDocument(point?._time, true)},{" "}
                            {formattedTime(point?._time)}
                          </span>
                        </span>
                      </div>
                      <div className="map-popup-image">
                        {point.image_url && point.limitFillingLevel ? (
                          <CropImage
                            data={point}
                            container={point}
                            imgDivWidth={280}
                            imgDivHeight={300}
                            isTooltip={true}
                            isMap={false}
                            isCharacterization={false}
                            displayLegend={false}
                            materialsDropdownToDisplay={[]}
                            showCheckbox={false}
                          />
                        ) : (
                          <ContainerVisual
                            type={point.type}
                            width={imageWidth}
                          />
                        )}
                      </div>
                      <article className="dashboard-card-footer">
                        <Link
                          to={{
                            pathname: "/containers",
                            state: {
                              keywordF: point._id,
                            },
                          }}
                        >
                          <div
                            className="dashboard-text-underline"
                            style={{ fontSize: 11 }}
                          >
                            Voir le détail
                          </div>
                        </Link>
                      </article>
                    </div>
                  </Popup>
                )}
              </Marker>
            ) : null;
          })}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
};

export default MapDisplay;
