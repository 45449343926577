import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import { getStartOfWeek } from "../../components/formatted/FormattedDateAndTime";
import CalendarComponent from "../../components/CalendarComponent";
import { useTranslation } from "react-i18next";
import { addCampaign } from "../../services/notification";

const CampaignCreateModal = ({
  modalCampaign,
  setModalCampaign,
  fetchCampaignsPeriods,
}) => {
  const { t } = useTranslation();
  const [periodCampaignStar, setPeriodCampaignStart] = useState(
    getStartOfWeek(new Date()).toISOString(),
  );
  const [periodCampaignStop, setPeriodCampaignStop] = useState(
    new Date().toISOString(),
  );
  const [displayCalendar, setDisplayCalendar] = useState(false);

  const onChangeCalendar = (event) => {
    setPeriodCampaignStart(new Date(event[0]).toISOString());
    setPeriodCampaignStop(new Date(event[1]).toISOString());
    setDisplayCalendar(false);
  };

  const toggle = () => setModalCampaign(!modalCampaign);

  const handleSubmit = async () => {
    await addCampaign({
      start: periodCampaignStar,
      stop: periodCampaignStop,
    }).then(async (res) => {
      if (res.status === 200) {
        await fetchCampaignsPeriods();
        setModalCampaign(false);
      }
    });
  };

  return (
    <Modal isOpen={modalCampaign} toggle={toggle} className="w-3/6">
      <ModalHeader>
        <h4>{t("Please define your sorting campaign")}</h4>
        <CloseIcon
          style={{
            color: "var(--gray)",
            cursor: "pointer",
          }}
          onClick={() => setModalCampaign(false)}
        />
      </ModalHeader>
      <div>
        <ModalBody>
          <div className="flex flex-col mt-3">
            <CalendarComponent
              classNameBase="flex flex-row "
              onChangeCalendar={onChangeCalendar}
              displayCalendar={displayCalendar}
              setDisplayCalendar={setDisplayCalendar}
              periodHistoryStart={periodCampaignStar}
              periodHistoryStop={periodCampaignStop}
              maxDate={false}
            />
          </div>
        </ModalBody>
        <ModalFooter className="information-footer-modal">
          <button
            className="button-submit"
            type="submit"
            onClick={() => handleSubmit()}
          >
            {t("Save")}
          </button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default CampaignCreateModal;
