import React from "react";
import AppHeaderBar from "./AppHeaderBar";
import AppNavBar from "./AppNavBar";
import { useTranslation } from "react-i18next";

const AppHome = () => {
  const storedUser = localStorage.getItem("user");
  const { t } = useTranslation();
  const { token, ...user } = storedUser ? JSON.parse(storedUser) : {};

  return (
    <div>
      <AppHeaderBar title="Akanthas" />
      <div className="mobileapp-container">
        <div className="flex flex-col mt-44 text-center text-gray-600">
          <span className="text-xl">{t("Welcome")}</span>
          <span className="text-xl">
            {user?.firstName} {user?.lastName}
          </span>
        </div>
      </div>
      <AppNavBar />
    </div>
  );
};

export default AppHome;
